const de = {
  network: {
    tips: `Das ausgewählte Netzwerk wird derzeit nicht unterstützt!
    Bitte wählen Sie das Matic (Polygon) Netzwerk!`,
    ev: "Bitte überprüfen Sie, ob Sie Wallet-Software wie MetaMask, Trust Wallet, imToken oder TokenPocket installiert haben.",
    copyight: process.env.VUE_APP_TITLE_NAME,
    all: "Max",
    bolck: "Dai Stablecoin",
    law: `<h3><strong style="color:yellow">Diese DAPP ist in bestimmten Ländern und Regionen nicht rechtlich geschützt. Bitte verwenden Sie sie mit Vorsicht!</strong></h3>
    <br/>
    <p><strong>Sehr geehrte Benutzer:</strong></p>
<br/>
    <p>Vielen Dank für Ihre Aufmerksamkeit und Unterstützung dieser DAPP. Um Ihre gesetzlichen Rechte zu gewährleisten und Sie über die damit verbundenen rechtlichen Risiken zu informieren, geben wir folgende wichtige Ankündigung bekannt:</p><br/>

    <p>Diese DAPP umfasst Investitionen und Transaktionen mit Kryptowährungen, die in bestimmten Ländern und Regionen gegen Gesetze verstoßen könnten. Aufgrund der örtlichen Gesetze, Vorschriften und Richtlinien der Aufsichtsbehörden kann diese DAPP in bestimmten Ländern und Regionen nicht legal betrieben werden. Daher erklären wir Folgendes:</p>
<br/>
    <h3><strong>1. Einschränkungen des Dienstleistungsbereichs:</strong></h3><br/>
    <p style="color:yellow">Diese DAPP bietet in den folgenden Ländern und Regionen keine Dienstleistungen im Bereich Kryptowährungsinvestitionen und -transaktionen an und bietet auch keinen technischen Support oder After-Sales-Service:</p><br/>
    <p style="color:yellow">China (einschließlich Festlandchina, der Sonderverwaltungsregion Hongkong, der Sonderverwaltungsregion Macau und Taiwan), Indien, Algerien, Bangladesch, Marokko, Ägypten, Nepal, Pakistan, Katar, Irak, Tunesien, Afghanistan, Bolivien.</p>
   <br/> <strong>Wir empfehlen Benutzern in den oben genannten Ländern und Regionen, die Nutzung dieser DAPP sofort einzustellen, um mögliche rechtliche Risiken zu vermeiden.</strong>
<br/><br/>
    <h3><strong>2. Warnung vor rechtlichen und Investitionsrisiken:</strong></h3><br/>
    <p>Jegliche Nutzung dieser DAPP für Kryptowährungsinvestitionen und -transaktionen in den oben genannten Ländern und Regionen kann schwerwiegende rechtliche Konsequenzen nach sich ziehen. Unser Team übernimmt keine rechtliche Verantwortung für solche Handlungen. Darüber hinaus ist der Kryptowährungsmarkt selbst hoch volatil und unvorhersehbar, und Investitionstätigkeiten sind mit erheblichen Risiken verbunden. Bitte stellen Sie sicher, dass Sie die damit verbundenen Risiken vollständig verstehen, bevor Sie entscheiden, ob Sie diese DAPP weiterhin für Investitionen und Transaktionen nutzen möchten.</p>
<br/>
    <h3><strong>3. Bestätigung durch den Benutzer:</strong></h3><br/>
    <p>Wenn Sie diese DAPP weiterhin für Kryptowährungsinvestitionen und -transaktionen verwenden, wird dies als Bestätigung gewertet, dass Sie den Inhalt dieser Ankündigung vollständig gelesen und verstanden haben und sich der damit verbundenen rechtlichen und Investitionsrisiken bewusst sind und bereit sind, diese zu tragen. Alle von Ihnen durchgeführten Handlungen gelten als freiwillige Übernahme der damit verbundenen Verantwortung, und das Betreiberteam übernimmt keine Verantwortung für etwaige daraus resultierende Folgen.</p>
<br/>
    <h3><strong>4. Erklärung des Betreiberteams:</strong></h3><br/>
    <p>Diese DAPP wurde von dem Bull Developer-Team entwickelt und wird von diesem betrieben. Wir halten uns stets an die Grundsätze der Einhaltung von Vorschriften und sind bestrebt, unseren Benutzern hochwertige Dienstleistungen zu bieten. Aufgrund der besonderen rechtlichen Rahmenbedingungen in bestimmten Ländern und Regionen können wir jedoch in diesen Gebieten keine Dienstleistungen im Zusammenhang mit Kryptowährungen mehr anbieten. Wir empfehlen allen Benutzern dringend, sich vor der Nutzung dieser DAPP über die Gesetze und Vorschriften ihres Landes oder ihrer Region zu informieren und diese einzuhalten, um rechtliche und Investitionsrisiken zu vermeiden.</p>
<br/>
    <p><strong>Vielen Dank nochmals für Ihr Verständnis und Ihre Unterstützung. Wenn Sie Fragen haben oder weitere Unterstützung benötigen, können Sie uns über unsere offizielle Website kontaktieren.</strong></p>
<br/>
    <p><strong>Mit freundlichen Grüßen!</strong></p>
<br/>
    <p><strong>Das Bull Developer-Team</strong></p>`,
    protocol: `Diese dezentrale Anwendung (DApp) birgt ungewisse Anlagerisiken. Bitte lesen und verstehen Sie vor der Benutzung die Risikowarnungen und Datenschutzrichtlinien gründlich.`,
    more: "Mehr",
    media: "Verwandte soziale Medien",
    stake:
      "Weltweite Eröffnung am 6. November 2023, folgen Sie uns auf Telegram und Twitter!",
    withdraw:
      "Weltweite Eröffnung am 6. November 2023, folgen Sie uns auf Telegram und Twitter!",
    Reinvest:
      "Weltweite Eröffnung am 6. November 2023, folgen Sie uns auf Telegram und Twitter!",
    Getreward:
      "Weltweite Eröffnung am 6. November 2023, folgen Sie uns auf Telegram und Twitter!",
    notification: "Der öffentliche Test des Falcon-Burn-Beschleunigung wurde erfolgreich abgeschlossen. Nun folgt die Bereitstellung des offiziellen Vertrags. Bleiben Sie gespannt!",
    flashloansOK:'Start erfolgreich!',
    flashloansNo:'Start fehlgeschlagen!',
    flashloansOnce:'Herzlichen Glückwunsch, Sie haben einmal die Chance zum Ausprobieren erhalten!',
    flashloansError:'Entschuldigung, die Gelegenheit zum Ausprobieren wurde bereits genutzt!',
    falocnerror:'Falcon™ V2 Testberechtigung nicht erteilt!',
    falocninfo:'Falcon™ V2 für Spieler, die auf V3-Niveau genehmigt wurden! Derzeit in der öffentlichen Testphase!',
    falconv4:'Das Falcon™V4 Arbitrage-Cluster befindet sich im Betatest (28000 Mal/Tag)!'
  },
  leavel: {
    0:"Samen",
    1: "Blackcurrant",
    2: "Kirschen",
    3: "Drachenfrucht",
    4: "Haselnuss",
    5: "Noni",
    6: "Kaki",
    7: "Trüffel",
    8: "Trüffel",
    9: "Trüffel",
    10: "Trüffel",
  },
  top: {
    Account: "Konto",
  },
  swap:{
    Swap:"Austausch",
    paynameA:"Zahlung",
    paynameB:"Zahlungseingang",
    Approve:"Autorisierung",
    launchSwap:"Austausch starten",
    recommend:"Empfehlung",
    swapError:"Austauschfehler",
    swapNetwork:"Netzwerkfehler",
  },
  stake: {
    Balance: "Ihr Guthaben",
    Total: "Gesamt-Stake",
    Appoove: "Genehmigen",
    Stake: "Stake",
    Appooveing: "Autorisierung",
    AuthorSuccessful: "Autorisierung Erfolgreich",
    AuthorFailed: "Autorisierung Gescheitert",
    Stakeing: "Einzahlung",
    StakeSuccessful: "Einzahlung Erfolgreich",
    StakeFailed: "Einzahlung Gescheitert",
    StakeInfo: "Darf nicht Weniger als Sein:",
    Activeaccount:"Konto aktivieren",
    active:"Activate"
  },
  menu: {
    Home: "Startseite",
    Language: "Sprache",
    Document: "Weißbuch",
    Contract: "Vertrag",
    Watch: "Beobachten",
    Coming: "Demnächst",
    EvDao: "BULLDAO",
    Falcon:"Falcon™",
    Flashloan: "Flashloan starten",
    Developer:"Entwicklerdokumente",
    Launch:"Launch",
    xNumber:"Verbleibend",
    xTips:"Automatische Verarbeitung",
    LogiV2:"Falcon™ V2",
    LogiV3:"Falcon™ V3",
    LogiV4:"Falcon™ V4",
    LogiV3tips:"Falcon™ V3 globales öffentliches Testing beginnt (tägliche Anfragen 1000~7000), Genehmigung für die entsprechenden Arbitrage-Verträge erforderlich!",
    Appoove2:"Auto",
    Market:"Market",
    Ido:"Falcon IDO",
    myIdo:"My Falcon",
    interest:"Gewinn",
    
  },
  ido:{
    select: "Community auswählen",
    flashmonkey: "Flash Affe",
    flashdragon: "Flash Drache",
    fashFalcon: "Falke",
    powermonger: "Power Monger",
    fashfalcon2: "FalconV2",
    other: "Andere",
    Getmax: "Maximalen",
    nowbuy: "Jetzt abonnieren",
    mynumber: "Mein Abonnement Gesamt",
    myrelease: "Freigegeben",
    waitrelease: "Warten auf Veröffentlichung",
    get: "Abheben",
    FalconF: "FlashFalcon(F)",
    FalconA: "FlashFalcon(A)",
    organization: "Organisation",
    endTimes:"Countdown zur ersten Runde",
    getmax:"Get Maximum",
    Authorization:"Genehmigung",
    Subscribe:"Jetzt abonnieren",
    okSubscribe:"Abonniert",
    message:"Knotenfehler, bitte Knoten wechseln",
    end1:"Erste Runde beendet",
    needStart:"Countdown bis zum Start",
    getscusse:"Abruf erfolgreich",
    geterror:"Abruf fehlgeschlagen"

  },
  Withdraw: {
    Withdraw: "Abheben",
    Withdrawable: "Abhebbar",
    Earnings: "Erträge",
    Brokerage: "Maklergebühr",
    Reinvest: "Reinvestieren",
    Getreward: "Belohnung erhalten",
    Processing: "In Bearbeitung",
    unsuccessfule: "Erfolglos",
    successful: "Erfolgreich!",
    WithdrawInfo1: "Bitte geben Sie die Abhebungsmenge ein",
    WithdrawInfo2: "Menge unzureichend",
    burning:"FalconBurn",
    noBurn: "No Burn",
    addBurn: "Zusätzlicher Gewinn",
    addBurnCost: "Zusätzliche Kosten",
    BurnCount: "Verbrennungskonto",
    BurnSpeed: "Verbrennungsbeschleunigung",
    needpay: "Zahlung erforderlich",
    startBurn: "Beschleunigung starten",
    Burning: "Beschleunigung läuft",
    noBurn1:"Paused",    
    cangetfalcon:"Voraussichtlich abhebbar",
    stakedassets:"Verpfändete Vermögenswerte "
  },
  Team: {
    innver: "Innver",
    Teamstaked: "Team-Stake",
    leavel: "Stufe",
    Team: "Team",
    Bind: "Verbindung",
    info: "Das System hat vorübergehend die Bindung angehalten. Bitte warten Sie auf die Öffnung",
    Binding: "Bindung Läuft",
    BindingSuccessful: "Bindung Erfolgreich",
    BindingFailed: " Bindung Fehlgeschlagen",
    Bindinfo: "Bitte geben Sie die Wallet-Adresse des Einladers ein",
  },
  Share: {
    Invitelink: "Einladungslink",
    Intips: `Dapp-Einladungen sind derzeit nicht verfügbar. 
            Bitte warten Sie auf die neuesten Community-Updates.`,
    copyTitle: "Einladungslink",
    copyText: "Der Einladungslink wurde erfolgreich kopiert!",
    copyCommnad: "Link kopieren",
  },
  Contract: {
    contractaddress: "Vertragsadresse",
    Copylink: "Polygonscan",
    CopylinkText: "Gehe zu polygonscan!",
  },
  Wathch: {
    watchonlywallet: "Wallet nur beobachten",
    addwallet: "Wallet hinzufügen",
    add: "Hinzufügen",
    Leavel: "Stufe",
    share: "Teilen",
    fnOpen:
      "Dieses Wallet wurde noch nicht zur Teilnahme an dieser Funktion eingeladen!",
    nullString: "Bitte geben Sie die Wallet-Adresse ein!",
    addok:"Erfolgreich hinzugefügt",
    addError:"Hinzufügen fehlgeschlagen",
    addisExists:"Diese Adresse existiert bereits",
    addlist:"Beobachtungsliste",
  },
  
};
export default de;
